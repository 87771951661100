/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import IconUpwork from "../images/icons/upwork.svg";
import IconFacebook from "../images/icons/facebook.svg";
import IconLinkedin from "../images/icons/linkedin.svg";
import IconGithub from "../images/icons/github.svg";
// import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <div className="page-wrapper bg-slate-900 min-h-screen relative z-10 overflow-hidden">
      <Header />
      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
      
      <main className="main">{children}</main>

      <footer className="footer py-6">
        <div className="container">
          <div className="flex flex-wrap items-center flex-col sm:flex-row-reverse justify-center -mx-4">
            <div className="flex-none px-4 mb-4 sm:mb-0">
              <ul className="flex m-0 -mx-2">
                <li className="px-2">
                  <a
                    className="flex justify-center items-center text-sky-600 bg-gray-800 hover:text-gray-100 hover:bg-sky-600 rounded-full transition duration-150 ease-in-out w-8 h-8"
                    href="https://www.upwork.com/freelancers/~01d61cfa1f8f6c1723"
                    target="_blank"
                    rel="nofollow noopener"
                  >
                    <IconUpwork />
                  </a>
                </li>

                <li className="px-2">
                  <a
                    className="flex justify-center items-center text-sky-600 bg-gray-800 hover:text-gray-100 hover:bg-sky-600 rounded-full transition duration-150 ease-in-out w-8 h-8"
                    href="https://www.linkedin.com/in/oleksii-fedoren-co/"
                    target="_blank"
                    rel="nofollow noopener"
                  >
                    <IconLinkedin />
                  </a>
                </li>

                <li className="px-2">
                  <a
                    className="flex justify-center items-center text-sky-600 bg-gray-800 hover:text-gray-100 hover:bg-sky-600 rounded-full transition duration-150 ease-in-out w-8 h-8"
                    href="https://www.facebook.com/oleksii.fedoren.co/"
                    target="_blank"
                    rel="nofollow noopener"
                  >
                    <IconFacebook />
                  </a>
                </li>

                <li className="px-2">
                  <a 
                    className="flex justify-center items-center text-sky-600 bg-gray-800 hover:text-gray-100 hover:bg-sky-600 rounded-full transition duration-150 ease-in-out w-8 h-8" 
                    href="https://github.com/alexeyff"
                    target="_blank"
                    rel="nofollow noopener"
                  >
                    <IconGithub />
                  </a>
                </li>
              </ul>
            </div>

            <div className="flex-1 px-4">
              <div className="copyright text-sm text-gray-200">
                © Oleksii Fedorenko {new Date().getFullYear()}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
